import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { CrudService } from '@services/laravel/crud.service';
import { saveAs } from 'file-saver';
import { QuillViewComponent } from 'ngx-quill';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-message',
  standalone: true,
  imports: [
    CommonModule,
    QuillViewComponent,
    MatExpansionModule,
    MatIconModule,
    MatTableModule,
  ],
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.css'],
})
export class MessageComponent {
  @Input() isParent;
  @Input() showSender;
  @Input() recipients;
  @Input() message;
  @Output() openRecipientList: EventEmitter<any> = new EventEmitter();
  public storageRoute: any = 'storage/message-attachments';
  displayedColumns: string[] = ['description', 'actions'];
  constructor(private _crud: CrudService, public toastr: ToastrService) {}

  downloadFile(attachment) {
    attachment.downloading = true;
    this._crud
      .downloadFileFromUrl(
        this.storageRoute + '/' + attachment.hashname,
        attachment.filename
      )
      .then((response) => {
        saveAs(response, attachment.filename);
        attachment.downloading = false;
      })
      .catch((err) => {
        attachment.downloading = false;
        this.toastr.error('Erro ao baixar anexo.', 'Erro!');
      });
  }

  viewRecipientList() {
    this.openRecipientList.emit();
  }
}
