import {
  Component,
  EventEmitter,
  Inject,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

/*Services*/
import { AuthenticationService } from '@services/laravel/authentication.service';
import { CrudService } from '@services/laravel/crud.service';
import { SnackBarService } from '@services/snackbar.service';
import { ValidateRequired } from '@shared/validators/required.validator';
import { ToastrService } from 'ngx-toastr';

export interface User {
  id?: string;
  description?: string;
}

export interface Application {
  id?: string;
  description?: string;
}

@Component({
  selector: 'app-import-users',
  templateUrl: './import-users.component.html',
  styleUrls: ['./import-users.component.css'],
})
export class ImportUsersComponent implements OnInit {
  @Output()
  importUsersChange: EventEmitter<string> = new EventEmitter<string>();
  @ViewChildren('eventCheckbox') private eventCheckbox: QueryList<any>;

  optionsUser: User[] = [];
  optionsApplication: Application[] = [];

  isLoading = true;
  validForm = false;
  public userArray: any;
  public eventArray: any;
  event_id: any;
  msg;
  importUsersForm: UntypedFormGroup;
  addUsersForm: UntypedFormGroup;
  myControlUser = new UntypedFormControl(null, [ValidateRequired]);
  myControlApplication = new UntypedFormControl(null, [ValidateRequired]);
  filteredOptionsUser: Observable<User[]>;
  filteredOptionsApplication: Observable<Application[]>;
  public associatedUsers: any = [];

  constructor(
    private authentication: AuthenticationService,
    public dialogRef: MatDialogRef<ImportUsersComponent>,
    private router: Router,
    private _crud: CrudService,
    public mdsnackbar: MatSnackBar,
    private dialog: MatDialog,
    public _snackbar: MatSnackBar,
    public snackBarService: SnackBarService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.listApplications();
    this.formInit();
    this.userFormInit();
    this.listUsers();
  }

  listApplications = () => {
    this._crud
      .get('public/events?order=description,asc&noPaginate=true')
      .then((res) => {
        this.eventArray = res['data'];

        for (let i = 0; i < this.eventArray.length; i++) {
          const application: Application = {
            id: this.eventArray[i].id.toString(),
            description: this.eventArray[i].description,
          };

          this.optionsApplication.push(application);
        }

        this.filteredOptionsApplication =
          this.myControlApplication.valueChanges.pipe(
            startWith<string | Application>(''),
            map((value) =>
              typeof value === 'string' ? value : value.description
            ),
            map((description) =>
              description
                ? this._filterApplication(description)
                : this.optionsApplication.slice()
            )
          );
      });
  };

  listUsers = () => {
    const route =
      'users/by-event?event=' +
      this.myControlApplication.value.id +
      '&search=' +
      this.myControlUser.value;
    this._crud.get(route).then((res) => {
      this.optionsUser = [];
      this.userArray = res;

      for (let i = 0; i < this.userArray.length; i++) {
        const user: User = {
          id: this.userArray[i].id.toString(),
          description:
            this.userArray[i].cpf_number + ' - ' + this.userArray[i].name,
        };

        this.optionsUser.push(user);
      }

      this.filteredOptionsUser = this.myControlUser.valueChanges.pipe(
        startWith<string | User>(''),
        map((value) => (typeof value === 'string' ? value : value.description)),
        map((name) =>
          name ? this._filterUser(name) : this.optionsUser.slice()
        )
      );
    });
  };

  displayFnUser(user?: User): string | undefined {
    return user ? user.description : undefined;
  }

  displayFnApplication(application?: Application): string | undefined {
    return application ? application.description : undefined;
  }

  private _filterUser(description: string): User[] {
    const filterValueUser = description.toLowerCase();
    return this.optionsUser.filter(
      (option) =>
        option.description.toLowerCase().indexOf(filterValueUser) === 0
    );
  }

  private _filterApplication(description: string): Application[] {
    const filterValueApplication = description.toLowerCase();
    return this.optionsApplication.filter(
      (optionApplication) =>
        optionApplication.description
          .toLowerCase()
          .indexOf(filterValueApplication) === 0
    );
  }

  formInit() {
    this.importUsersForm = new UntypedFormGroup({
      user_id: new UntypedFormControl([], [Validators.required]),
      source_event_id: new UntypedFormControl('', [Validators.required]),
      destination_event_id: new UntypedFormControl(this.event_id),
    });
  }

  userFormInit() {
    this.addUsersForm = new UntypedFormGroup({
      user_id: new UntypedFormControl([], [Validators.required]),
      event_id: new UntypedFormControl(this.event_id, [Validators.required]),
    });
  }

  addUser() {
    const user = this.myControlUser.value;
    this.importUsersForm.get('user_id').setValue(user.id);
  }

  addApplication() {
    const application = this.myControlApplication.value;
    this.importUsersForm.get('source_event_id').setValue(application.id);
    this.listUsers();
  }

  close = () => {
    this.dialogRef.close();
  };

  importUsersFromEvent = () => {
    this._crud.post('events/users', this.importUsersForm.value).then((res) => {
      this.toastr.success('Usuário adicionado com Sucesso!', 'Sucesso!');
      this.dialogRef.close();
    });
  };
}
