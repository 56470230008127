import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-characters',
  templateUrl: './characters.component.html',
  styleUrls: ['./characters.component.css'],
})
export class CharactersComponent {
  constructor(public dialogRef: MatDialogRef<CharactersComponent>) {}

  close() {
    this.dialogRef.close();
  }
}
