import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

/*Services*/
import { CrudService } from '@services/laravel/crud.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-ntm-application-select',
  templateUrl: './application-select.component.html',
  styleUrls: ['./application-select.component.css'],
})
export class ApplicationSelectComponent implements OnInit {
  mensagem: any;
  status: any;
  color: any;

  hasChange: any = false;

  applications: any;
  default_application: any;

  alert_type_1: any = false;
  alert_type_2: any = false;

  applicationForm: UntypedFormGroup;

  dataToDelete: any;
  dialogMessage: string;
  constructor(
    public dialogRef: MatDialogRef<ApplicationSelectComponent>,
    private crud: CrudService,
    private router: Router,
    private matsnackbar: MatSnackBar
  ) {}

  ngOnInit() {
    this.applicationForm = new UntypedFormGroup({
      id: new UntypedFormControl(null, [Validators.required]),
    });

    for (let i = 0; i < this.applications.length; i++) {
      if (this.applications[i]['id'] == this.default_application['id']) {
        this.applications[i]['markColor'] =
          this.default_application['hexadecimalColor'];
        this.applications[i]['checked'] = true;
      } else {
        this.applications[i]['markColor'] = '#FFF';
      }
    }
  }

  onSelection(application) {
    this.hasChange = true;
    for (let i = 0; i < this.applications.length; i++) {
      if (this.applications[i]['id'] == application.id) {
        this.default_application = application;

        this.applications[i]['markColor'] = application['hexadecimalColor'];
        this.applications[i]['checked'] = true;
      } else {
        this.applications[i]['markColor'] = '#FFF';
      }
    }
  }

  applyApplication() {
    this.dialogRef.close(this.default_application);
  }

  onDialogClose = () => {
    this.dialogRef.close();
  };

  applicationIsRunning(initialDate) {
    return new Date(initialDate) < new Date();
  }
}
