import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';

/*Services*/
import { CrudService } from '@services/laravel/crud.service';
import { ViewRecipientListComponent } from '../view-recipient-list/view-recipient-list.component';

@Component({
  selector: 'app-ntm-view-message',
  templateUrl: './view-message.component.html',
  styleUrls: ['./view-message.component.css'],
})
export class ViewMessageComponent implements OnInit {
  @Output()
  viewChange: EventEmitter<string> = new EventEmitter<string>();

  messageType: any;
  title: any;
  messageId: any;
  message: any;
  showSender = false;

  dataToDelete: any;
  dialogMessage: string;
  recipients = '';
  constructor(
    public dialogRef: MatDialogRef<ViewMessageComponent>,
    private crud: CrudService,
    private router: Router,
    private matsnackbar: MatSnackBar,
    private dialog: MatDialog,
    private sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    if (this.messageType == 1) {
      this.showSender = true;
      this.loadMessageReceived();
    } else {
      this.showSender = false;
      this.loadMessageSended();
    }
  }

  loadMessageReceived() {
    this.crud.get('messages-receivers/' + this.messageId).then((res) => {
      this.message = res['message'];

      if (this.message.group_receivers) {
        // this.recipients += '<i class="bold-text">Destinatário(s):</i>';
        for (let g = 0; g < this.message.group_receivers.length; g++) {
          this.recipients +=
            "<i class='material-icons'>group</i>" +
            this.message.group_receivers[g]['perfil'];

          if (this.message.group_receivers[g]['regional']) {
            this.recipients +=
              ' - ' + this.message.group_receivers[g]['regional'];
            this.recipients += '<br>';
          }

          if (this.message.group_receivers[g]['escola']) {
            this.recipients +=
              ' - ' + this.message.group_receivers[g]['escola'];
            this.recipients += '<br>';
          }

          if (this.message.group_receivers[g]['curso']) {
            this.recipients += ' - ' + this.message.group_receivers[g]['curso'];
            this.recipients += '<br>';
          }
          // this.recipients += '<br>';
        }
      }

      if (this.message.users_receivers) {
        for (let u = 0; u < this.message.users_receivers.length; u++) {
          const arrayName = this.message.users_receivers[u]['name'].split(' ');
          if (arrayName.length > 1) {
            this.message.users_receivers[u]['name'] =
              arrayName[0] + ' ' + arrayName[arrayName.length - 1];
          }

          this.recipients +=
            "<i class='material-icons'>person</i>" +
            this.message.users_receivers[u]['name'] +
            ' ';
          this.recipients += '<br>';
        }
      }

      this.message['message'] = this.sanitizer.bypassSecurityTrustHtml(
        this.message['message']
      );
    });
  }

  loadMessageSended() {
    this.crud.get('messages/' + this.messageId).then((res) => {
      this.message = res;

      if (this.message.groups_receivers) {
        for (let g = 0; g < this.message.groups_receivers.length; g++) {
          this.recipients +=
            "<i class='material-icons'>group</i>" +
            this.message.groups_receivers[g]['perfil'];

          if (this.recipients.length > 100) {
            this.recipients =
              "<i class='material-icons'>group</i>" +
              this.message.groups_receivers[0]['perfil'] +
              '...';
          }
        }
      }

      if (this.message.users_receivers) {
        for (let u = 0; u < this.message.users_receivers.length; u++) {
          const arrayName = this.message.users_receivers[u]['name'].split(' ');
          if (arrayName.length > 1) {
            this.message.users_receivers[u]['name'] =
              arrayName[0] + ' ' + arrayName[arrayName.length - 1];
          }

          this.recipients +=
            "<i class='material-icons'>person</i>" +
            this.message.users_receivers[u]['name'] +
            ' ';
          this.recipients += '<br>';

          if (this.recipients.length > 100) {
            this.recipients =
              "<i class='material-icons'>group</i>" +
              this.message.users_receivers[0]['name'] +
              '...';
          }
        }
      }

      this.recipients += "<i class='material-icons pointer'>zoom_in</i>";

      this.message['message'] = this.sanitizer.bypassSecurityTrustHtml(
        this.message['message']
      );
    });
  }

  viewRecipientList() {
    this.crud.get('messages/tracking-message/' + this.messageId).then((res) => {
      const dialogRef = this.dialog.open(ViewRecipientListComponent, {
        width: '600px',
        height: 'auto',
      });

      dialogRef.componentInstance.messageId = this.messageId;
      dialogRef.componentInstance.message = res;
      dialogRef.componentInstance.groups = this.message.groups_receivers;
      dialogRef.componentInstance.users = this.message.users_receivers;
    });
  }

  onDialogClose = () => {
    this.dialogRef.close();
  };
}
