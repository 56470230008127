import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef } from '@angular/core';

@Component({
  selector: 'app-support-card',
  templateUrl: './support-card.component.html',
  styleUrls: ['./support-card.component.scss'],
  animations: [
    trigger('openClose', [
      state(
        'open',
        style({
          transform: 'translateX(15rem)',
        })
      ),
      state(
        'closed',
        style({
          transform: 'translateX(0)',
        })
      ),
      transition('open => closed', [animate('500ms ease-out')]),
      transition('closed => open', [animate('500ms ease-in')]),
    ]),
  ],
})
export class SupportCardComponent {
  public expandCard = false;

  constructor(private host: ElementRef<HTMLElement>) {}

  toggle() {
    this.expandCard = !this.expandCard;
  }

  closeModal() {
    this.host.nativeElement.remove();
  }
}
