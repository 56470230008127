import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { PreparationTestComponent } from './components/preparation-test/preparation-test.component';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';

/**
 * Guards
 */
import { AuthGuard } from './guards/auth.guard';

/**
 * Modules
 */
import { ComponentModule } from './component.module';
import { MaterialModule } from './material.module';

/**
 * Services
 */
import { AuthenticationService } from '@services/laravel/authentication.service';
import { CrudService } from '@services/laravel/crud.service';
import { FeedService } from '@services/laravel/feed.service';
import { ImportService } from '@services/laravel/import.service';
import { SnackBarService } from '@services/snackbar.service';
import { AddClassComponent } from './components/add-class/add-class.component';
import { AddCourseComponent } from './components/add-course/add-course.component';
import { AddEvidenceToLvComponent } from './components/add-evidence-to-lv/add-evidence-to-lv.component';
import { AddProfileModalComponent } from './components/add-profile-modal/add-profile-modal.component';
import { AddRecipientsComponent } from './components/add-recipients/add-recipients.component';
import { AlertConfirmComponent } from './components/alert-confirm/alert-confirm.component';
import { ApplicationSelectComponent } from './components/application-select/application-select.component';
import { AssociateEventsComponent } from './components/associate-events/associate-events.component';
import { AttachFileComponent } from './components/attach-file/attach-file.component';
import { CoverSheetComponent } from './components/cover-sheet/cover-sheet.component';
import { DeleteConfirmComponent } from './components/delete-confirm/delete-confirm.component';
import { EvaluatorManualPreviewComponent } from './components/evaluator-manual-preview/evaluator-manual-preview.component';
import { ExamLocationComponent } from './components/exam-location/exam-location.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ImportCoverSheetsComponent } from './components/import-cover-sheets/import-cover-sheets.component';
import { ImportMatricesComponent } from './components/import-matrices/import-matrices.component';
import { ImportSchoolsComponent } from './components/import-schools/import-schools.component';
import { ImportUsersFromEventComponent } from './components/import-users-from-event/import-users-from-event.component';
import { ImportUsersComponent } from './components/import-users/import-users.component';
import { ImportVerificationListComponent } from './components/import-verification-list/import-verification-list.component';
import { InputDialogComponent } from './components/input-dialog/input-dialog.component';
import { ListViewComponent } from './components/list-view/list-view.component';
import { LoadingModalComponent } from './components/loading-modal/loading-modal.component';
import { LogoutComponent } from './components/logout/logout.component';
import { MenuSidenavComponent } from './components/menu-sidenav/menu-sidenav.component';
import { ModalConfirmComponent } from './components/modal-confirm/modal-confirm.component';
import { NtmTableDataComponent } from './components/ntm-table-data/ntm-table-data.component';
import { PickImageComponent } from './components/pick-image/pick-image.component';
import { ReAuditModalComponent } from './components/re-audit-modal/re-audit-modal.component';
import { RelateCourseToApplicationComponent } from './components/relate-course-to-application/relate-course-to-application.component';
import { SelectTestsToApplicationComponent } from './components/select-tests-to-application/select-tests-to-application.component';
import { SetUserProfileModalComponent } from './components/set-user-profile-modal/set-user-profile-modal.component';
import { ViewMessageComponent } from './components/view-message/view-message.component';
/**
 * Components
 */
import { ImageCropperModule } from 'ngx-img-cropper';
import { ViewMediaComponent } from './components/view-media/view-media.component';
import { NtmDndDirective } from './directives/ntm-dnd.directive';

import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { NgxYoutubePlayerModule } from '@hercilio/ngx-youtube-player';
import { AppointmentComponent } from '@main/components/appointment/appointment.component';
import { CloneMatrixComponent } from '@main/components/clone-matrix/clone-matrix.component';
import { ExamGeneratedComponent } from '@main/components/exam-generated/exam-generated.component';
import { HomologationComponent } from '@main/components/homologation/homologation.component';
import { ResultsComponent } from '@main/components/results/results.component';
import { NgxCaptchaModule } from 'ngx-captcha';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { NgPipesModule } from 'ngx-pipes';
import { ToastrModule } from 'ngx-toastr';
import { CharactersComponent } from './components/characters/characters.component';
import { CommitmentTermModalComponent } from './components/commitment-term-modal/commitment-term-modal.component';
import { ImportEvaluatorManualComponent } from './components/import-evaluator-manual/import-evaluator-manual.component';
import { ImportExamComponent } from './components/import-exam/import-exam.component';
import { ImportItensPreparationCenterComponent } from './components/import-itens-preparation-center/import-itens-preparation-center.component';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { LoadingComponent } from './components/loading/loading.component';
import { ModalHeaderComponent } from './components/modal-header/modal-header.component';
import { SupportCardComponent } from './components/support-card/support-card.component';
import { MessageComponent } from './components/view-message/message/message.component';
import { ViewRecipientListComponent } from './components/view-recipient-list/view-recipient-list.component';
import { FocusFirstInvalidFieldDirective } from './directives/focus-first-invalid-field.directive';
import { OnlyNumberDirective } from './directives/only-number.directive';

@NgModule({
  exports: [
    FocusFirstInvalidFieldDirective,
    AlertConfirmComponent,
    MessageComponent,
    MenuSidenavComponent,
    ComponentModule,
    LogoutComponent,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    DeleteConfirmComponent,
    ReAuditModalComponent,
    ModalConfirmComponent,
    HomologationComponent,
    AppointmentComponent,
    ResultsComponent,
    ExamGeneratedComponent,
    InputDialogComponent,
    ForgotPasswordComponent,
    PickImageComponent,
    ImportUsersFromEventComponent,
    LoadingComponent,
    LoadingSpinnerComponent,
    ModalHeaderComponent,
    ImportUsersComponent,
    CoverSheetComponent,
    EvaluatorManualPreviewComponent,
    ListViewComponent,
    SetUserProfileModalComponent,
    SelectTestsToApplicationComponent,
    ApplicationSelectComponent,
    ExamLocationComponent,
    AddCourseComponent,
    AddClassComponent,
    ImportSchoolsComponent,
    ImportCoverSheetsComponent,
    ImportEvaluatorManualComponent,
    ImportItensPreparationCenterComponent,
    ImportExamComponent,
    ImportVerificationListComponent,
    ViewMessageComponent,
    AddRecipientsComponent,
    AttachFileComponent,
    AddProfileModalComponent,
    ViewRecipientListComponent,
    AddEvidenceToLvComponent,
    RelateCourseToApplicationComponent,
    AssociateEventsComponent,
    ImportMatricesComponent,
    CloneMatrixComponent,
    LoadingModalComponent,
    CommitmentTermModalComponent,
    NtmTableDataComponent,
    MatTableModule,
    MatBadgeModule,
    MatPaginatorModule,
    MatSortModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatRadioModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatStepperModule,
    MatToolbarModule,
    MatListModule,
    MatMenuModule,
    MatExpansionModule,
    MatTooltipModule,
    MatSidenavModule,
    MatDividerModule,
    MatProgressSpinnerModule,
    DragDropModule,
    MatTabsModule,
    NtmDndDirective,
    OnlyNumberDirective,
    CharactersComponent,
    SupportCardComponent,
    NgxCaptchaModule,
    NgxMaskDirective,
    NgxMaskPipe,
  ],
  declarations: [
    FocusFirstInvalidFieldDirective,
    AlertConfirmComponent,
    MenuSidenavComponent,
    DeleteConfirmComponent,
    ReAuditModalComponent,
    ModalConfirmComponent,
    HomologationComponent,
    AppointmentComponent,
    ResultsComponent,
    ExamGeneratedComponent,
    InputDialogComponent,
    ForgotPasswordComponent,
    PickImageComponent,
    ImportUsersFromEventComponent,
    LoadingComponent,
    LoadingSpinnerComponent,
    ModalHeaderComponent,
    ImportUsersComponent,
    CoverSheetComponent,
    EvaluatorManualPreviewComponent,
    ListViewComponent,
    SetUserProfileModalComponent,
    SelectTestsToApplicationComponent,
    ApplicationSelectComponent,
    ExamLocationComponent,
    AddCourseComponent,
    AddClassComponent,
    ImportSchoolsComponent,
    ImportCoverSheetsComponent,
    ImportEvaluatorManualComponent,
    ImportItensPreparationCenterComponent,
    ImportExamComponent,
    ImportVerificationListComponent,
    ViewMessageComponent,
    AddRecipientsComponent,
    AttachFileComponent,
    AddProfileModalComponent,
    ViewRecipientListComponent,
    AddEvidenceToLvComponent,
    RelateCourseToApplicationComponent,
    AssociateEventsComponent,
    ImportMatricesComponent,
    CloneMatrixComponent,
    LoadingModalComponent,
    CommitmentTermModalComponent,
    LogoutComponent,
    NtmTableDataComponent,
    NtmDndDirective,
    OnlyNumberDirective,
    ViewMediaComponent,
    PreparationTestComponent,
    CharactersComponent,
    SupportCardComponent,
  ],
  imports: [
    MessageComponent,
    MatTableModule,
    ImageCropperModule,
    MatPaginatorModule,
    MatSortModule,
    CommonModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatProgressSpinnerModule,
    DragDropModule,
    MatTabsModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatRadioModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatStepperModule,
    MatToolbarModule,
    MatTooltipModule,
    MatSidenavModule,
    MatListModule,
    MatMenuModule,
    MatExpansionModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    NgxYoutubePlayerModule.forRoot(),
    NgPipesModule,
    RouterModule,
    NgOptimizedImage,
    NgxCaptchaModule,
    NgxMaskDirective,
    NgxMaskPipe,
  ],
  providers: [
    AuthenticationService,
    AuthGuard,
    CrudService,
    FeedService,
    ImportService,
    SnackBarService,
    LoadingModalComponent,
    CommitmentTermModalComponent,
    provideNgxMask(),
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class SharedModule {}
