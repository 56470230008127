import {
  Component,
  Inject,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { saveAs } from 'file-saver';
import { Observable } from 'rxjs';

/*Services*/
import { AuthenticationService } from '@services/laravel/authentication.service';
import { CrudService } from '@services/laravel/crud.service';
import { SnackBarService } from '@services/snackbar.service';
import { LoadingModalComponent } from '@shared/components/loading-modal/loading-modal.component';
import { ToastrService } from 'ngx-toastr';

export interface User {
  id?: string;
  description?: string;
}

export interface Application {
  id?: string;
  description?: string;
}

@Component({
  selector: 'app-import-evaluator-manual',
  templateUrl: './import-evaluator-manual.component.html',
  styleUrls: ['./import-evaluator-manual.component.css'],
})
export class ImportEvaluatorManualComponent implements OnInit {
  @ViewChildren('evaluatorManualCheckbox')
  private evaluatorManualCheckbox: QueryList<any>;

  displayedColumns: string[] = ['select', 'type', 'particularity', 'preview'];

  optionsUser: User[] = [];
  optionsApplication: Application[] = [];

  isLoading = false;
  validForm = false;
  public userArray: any;
  public eventArray: any;
  public applicationArray: any;
  public evaluatorManualArray: any = [];
  public courseArray: any;
  event_id: any;
  msg;
  importEvaluatorManualForm: UntypedFormGroup;
  addUsersForm: UntypedFormGroup;
  myControlUser = new UntypedFormControl();
  myControlApplication = new UntypedFormControl();
  filteredOptionsUser: Observable<User[]>;
  filteredOptionsApplication: Observable<Application[]>;
  public associatedUsers: any = [];

  constructor(
    private authentication: AuthenticationService,
    public dialogRef: MatDialogRef<ImportEvaluatorManualComponent>,
    private router: Router,
    private _crud: CrudService,
    public mdsnackbar: MatSnackBar,
    private dialog: MatDialog,
    public _snackbar: MatSnackBar,
    public snackBarService: SnackBarService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.formInit();
    this.listApplications();
    this.listCourses();
  }

  listApplications = () => {
    this.isLoading = true;
    this._crud
      .post('events/general-search', {
        fields: {},
        dates: {},
        orderBy: { description: 'asc' },
        limit: null,
        noPaginate: true,
        page: null,
      })
      .then((res) => {
        this.applicationArray = res['data'];
        this.isLoading = false;
      });
  };

  listCourses = () => {
    this._crud
      .post('course-event/general-search', {
        fields: {},
        dates: {},
        // "orderBy": { 'description': 'asc' },
        where: {
          event_id: this.importEvaluatorManualForm.value.source_event_id,
        },
        limit: null,
        noPaginate: true,
        page: null,
      })
      .then((res) => {
        this.courseArray = res['data'];
      });
  };

  listEvaluatorManuals() {
    this.isLoading = true;
    const route =
      'evaluators-manuals?where[]=event_id,' +
      this.importEvaluatorManualForm.value.source_event_id +
      '&where[]=course_id,' +
      this.importEvaluatorManualForm.value.course_id +
      '&where[]=is_active,1';
    this._crud.get(route).then((res: any) => {
      this.evaluatorManualArray = res['data'];
      this.isLoading = false;
    });
  }

  formInit() {
    this.importEvaluatorManualForm = new UntypedFormGroup({
      source_event_id: new UntypedFormControl(null, [Validators.required]),
      course_id: new UntypedFormControl(null, [Validators.required]),
      destination_event_id: new UntypedFormControl(this.event_id),
      manuals_ids: new UntypedFormControl(null),
    });
  }

  close = () => {
    this.dialogRef.close();
  };

  previewEvaluatorManual(manual) {
    this.loadingMessage();

    this._crud
      .file('evaluators-manuals/preview', {
        id: manual.id,
      })
      .then(
        (res) => {
          saveAs(res, 'manual_avaliador_preview.pdf');
          this.dialog.closeAll();
        },
        (rej) => {
          this.toastr.error('Falha do tentar gerar Preview', 'Erro!');
          this.dialog.closeAll();
        }
      );
  }

  loadingMessage() {
    const array = [];
    const string = '';

    const dialogRef = this.dialog.open(LoadingModalComponent, {
      width: '520px',
      height: 'auto',
      disableClose: true,
    });
    dialogRef.componentInstance.title = 'Aguarde...';
    dialogRef.componentInstance.mensagem = 'Carregando dados...';
    dialogRef.componentInstance.showBtn = false;
  }

  submitForm() {
    const evaluatorManualCheckbox = this.evaluatorManualCheckbox.toArray();
    const manuals_ids = [];

    for (let i = 0; i < evaluatorManualCheckbox.length; i++) {
      if (evaluatorManualCheckbox[i].checked) {
        manuals_ids.push(evaluatorManualCheckbox[i].value.toString());
      }
    }

    this.importEvaluatorManualForm.get('manuals_ids').setValue(manuals_ids);

    this._crud
      .post(
        'evaluation-items/evaluator-manual',
        this.importEvaluatorManualForm.value
      )
      .then((res) => {
        this.toastr.success('Manuais importados com Sucesso!', 'Sucesso!');
        this.dialogRef.close();
      });
  }
}
