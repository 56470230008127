import {
  Component,
  EventEmitter,
  Inject,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

/*Services*/
import { AuthenticationService } from '@services/laravel/authentication.service';
import { CrudService } from '@services/laravel/crud.service';
import { SnackBarService } from '@services/snackbar.service';
import { LoadingModalComponent } from '@shared/components/loading-modal/loading-modal.component';
import { ToastrService } from 'ngx-toastr';

export interface User {
  id?: string;
  description?: string;
}

export interface Application {
  id?: string;
  description?: string;
}

@Component({
  selector: 'app-import-itens-preparation-center',
  templateUrl: './import-itens-preparation-center.component.html',
  styleUrls: ['./import-itens-preparation-center.component.css'],
})
export class ImportItensPreparationCenterComponent implements OnInit {
  @Output()
  ImportItensPreparationCenterChange: EventEmitter<string> =
    new EventEmitter<string>();
  @ViewChildren('itemsCheckbox') private itemsCheckbox: QueryList<any>;

  optionsUser: User[] = [];
  optionsApplication: Application[] = [];

  isLoading = false;
  validForm = false;
  public userArray: any;
  public eventArray: any;
  public applicationArray: any;
  public itemsArray: any = [];
  public documentsArray: any = [];
  public preparationTestsArray: any = [];
  event_id: any;
  msg;
  title;
  subtitle;
  displayedColumns;
  type;
  importItensPreparationCenterForm: UntypedFormGroup;
  addUsersForm: UntypedFormGroup;
  myControlUser = new UntypedFormControl();
  myControlApplication = new UntypedFormControl();
  filteredOptionsUser: Observable<User[]>;
  filteredOptionsApplication: Observable<Application[]>;
  public associatedUsers: any = [];

  constructor(
    private authentication: AuthenticationService,
    public dialogRef: MatDialogRef<ImportItensPreparationCenterComponent>,
    private router: Router,
    private _crud: CrudService,
    public mdsnackbar: MatSnackBar,
    private dialog: MatDialog,
    public _snackbar: MatSnackBar,
    public snackBarService: SnackBarService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.formInit();
    this.listApplications();
  }

  listApplications = () => {
    this._crud
      .post('events/general-search', {
        fields: {},
        dates: {},
        orderBy: { description: 'asc' },
        limit: null,
        noPaginate: true,
        page: null,
      })
      .then((res) => {
        this.applicationArray = res['data'];
      });
  };

  listLessonsVideos() {
    this.isLoading = true;
    const route =
      'lessons-videos/get-lessons?event_id=' +
      this.importItensPreparationCenterForm.value.source_event_id;
    this._crud.get(route).then((res: any) => {
      this.itemsArray = res || [];
      this.isLoading = false;
    });
  }

  listDocuments() {
    this.isLoading = true;
    const route =
      'documents/application?event_id=' +
      this.importItensPreparationCenterForm.value.source_event_id;
    this._crud.get(route).then((res: any) => {
      this.itemsArray = res || [];
      this.isLoading = false;
    });
  }

  listPreparationTests() {
    this.isLoading = true;
    const route =
      'preparation-test/application?event_id=' +
      this.importItensPreparationCenterForm.value.source_event_id;
    this._crud.get(route).then((res: any) => {
      this.itemsArray = res || [];
      this.isLoading = false;
    });
  }

  makeList() {
    if (this.type === 'document') {
      this.listDocuments();
    } else if (this.type === 'video') {
      this.listLessonsVideos();
    } else if (this.type === 'test') {
      this.listPreparationTests();
    }
  }

  formInit() {
    this.importItensPreparationCenterForm = new UntypedFormGroup({
      source_event_id: new UntypedFormControl(null, [Validators.required]),
      destination_event_id: new UntypedFormControl(this.event_id),
      lessons_videos_ids: new UntypedFormControl(null),
      documents_ids: new UntypedFormControl(null),
      preparations_tests_ids: new UntypedFormControl(null),
    });
  }

  close = () => {
    this.dialogRef.close();
  };

  loadingMessage() {
    const array = [];
    const string = '';

    const dialogRef = this.dialog.open(LoadingModalComponent, {
      width: '520px',
      height: 'auto',
      disableClose: true,
    });
    dialogRef.componentInstance.title = 'Aguarde...';
    dialogRef.componentInstance.mensagem = 'Carregando dados...';
    dialogRef.componentInstance.showBtn = false;
  }

  submitForm() {
    const itemsCheckbox = this.itemsCheckbox.toArray();
    const lessons_videos_ids = [];
    const documents_ids = [];
    const preparations_tests_ids = [];

    for (let i = 0; i < itemsCheckbox.length; i++) {
      if (itemsCheckbox[i].checked) {
        if (this.type === 'video') {
          lessons_videos_ids.push(itemsCheckbox[i].value.toString());
        } else if (this.type === 'document') {
          documents_ids.push(itemsCheckbox[i].value.toString());
        } else if (this.type === 'test') {
          preparations_tests_ids.push(itemsCheckbox[i].value.toString());
        }
      }
    }

    this.importItensPreparationCenterForm
      .get('lessons_videos_ids')
      .setValue(lessons_videos_ids);
    this.importItensPreparationCenterForm
      .get('documents_ids')
      .setValue(documents_ids);
    this.importItensPreparationCenterForm
      .get('preparations_tests_ids')
      .setValue(preparations_tests_ids);

    if (lessons_videos_ids.length > 0) {
      this._crud
        .post(
          'imports/lessons-videos',
          this.importItensPreparationCenterForm.value
        )
        .then((res) => {
          this.toastr.success('Videoaulas importadas com Sucesso!', 'Sucesso!');
          this.dialogRef.close();
        });
    }

    if (documents_ids.length > 0) {
      this._crud
        .post('imports/documents', this.importItensPreparationCenterForm.value)
        .then((res) => {
          this.toastr.success('Documentos importados com Sucesso!', 'Sucesso!');
          this.dialogRef.close();
        });
    }

    if (preparations_tests_ids.length > 0) {
      this._crud
        .post(
          'imports/preparations-tests',
          this.importItensPreparationCenterForm.value
        )
        .then((res) => {
          this.toastr.success('Testes importados com Sucesso!', 'Sucesso!');
          this.dialogRef.close();
        });
    }
  }
}
