import { Component, Inject, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

/*Services*/
import { AuthenticationService } from '@services/laravel/authentication.service';
import { CrudService } from '@services/laravel/crud.service';
import { SnackBarService } from '@services/snackbar.service';
import { ToastrService } from 'ngx-toastr';

export interface Event {
  value: string;
  viewValue: string;
}

export interface User {
  id?: string;
  description?: string;
}

@Component({
  selector: 'app-associate-events',
  templateUrl: './associate-events.component.html',
  styleUrls: ['./associate-events.component.css'],
})
export class AssociateEventsComponent implements OnInit {
  events: Event[] = [
    { value: '1', viewValue: 'Evento 1' },
    { value: '2', viewValue: 'Evento 2' },
    { value: '3', viewValue: 'Evento 3' },
    { value: '4', viewValue: 'Evento 4' },
    { value: '5', viewValue: 'Evento 5' },
  ];

  options: User[] = [];

  isLoading = true;
  validForm = false;
  public userArray: any;
  public associatedEvents: any = [];
  msg;
  importUsersForm: UntypedFormGroup;
  addUsersForm: UntypedFormGroup;
  myControl = new UntypedFormControl();
  filteredOptions: Observable<User[]>;
  user_id: any;

  constructor(
    private authentication: AuthenticationService,
    public dialogRef: MatDialogRef<AssociateEventsComponent>,
    private router: Router,
    private _crud: CrudService,
    private dialog: MatDialog,
    public _snackbar: MatSnackBar,
    public snackBarService: SnackBarService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.formInit();
    this.userFormInit();
    this.listUsers();
  }

  formInit() {
    this.importUsersForm = new UntypedFormGroup({
      user: new UntypedFormControl('', [Validators.required]),
    });
  }

  userFormInit() {
    this.addUsersForm = new UntypedFormGroup({
      user_id: new UntypedFormControl(this.user_id, [Validators.required]),
      event_id: new UntypedFormControl([], [Validators.required]),
    });
  }

  displayFn(user?: User): string | undefined {
    return user ? user.description : undefined;
  }

  private _filter(description: string): User[] {
    const filterValue = description.toLowerCase();

    return this.options.filter(
      (option) => option.description.toLowerCase().indexOf(filterValue) === 0
    );
  }

  listUsers = () => {
    this._crud.get('public/events?order=description,asc').then((res) => {
      this.userArray = res['obj'];

      for (let i = 0; i < this.userArray.length; i++) {
        var user: User = {
          id: this.userArray[i].id.toString(),
          description: this.userArray[i].description,
        };

        this.options.push(user);
      }

      this.filteredOptions = this.myControl.valueChanges.pipe(
        startWith<string | User>(''),
        map((value) => (typeof value === 'string' ? value : value.description)),
        map((name) => (name ? this._filter(name) : this.options.slice()))
      );
    });
  };

  close = () => {
    this.dialogRef.close();
  };

  addUser() {
    let user = this.myControl.value;
    this.myControl = new UntypedFormControl();
    this.addUsersForm.value['event_id'].push({ id: user.id });
    this.associatedEvents.push({ id: user.id, description: user.description });

    this.options = [];

    this.formInit();

    this.listUsers();
    this.validForm = true;
  }

  removeAplication(application) {
    let application_id = application.id;
    let index = this.addUsersForm.value['event_id'].indexOf(application);
    let index2 = this.associatedEvents.indexOf(application);
    this.addUsersForm.value['event_id'].splice(index, 1);
    this.associatedEvents.splice(index2, 1);
  }

  associateEvents = () => {
    this.addUsersForm.value['event_id'] = this.addUsersForm.value[
      'event_id'
    ].map((value) => {
      return value.id;
    });

    this._crud.post('events/users', this.addUsersForm.value).then((res) => {
      let snackClass, string;
      snackClass = 'success-snackbar';

      if (res && res['apiBody']['status'] === 'ERROR') {
        string = res['apiBody']['message'];
        this.toastr.success(string, 'Sucesso!');
      } else {
        this.toastr.success('Eventos adicionados com Sucesso.', 'Sucesso!');
        this.dialogRef.close();
      }
    });
  };
}
