import {
  Component,
  EventEmitter,
  Inject,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { saveAs } from 'file-saver';
import { Observable } from 'rxjs';

/*Services*/
import { AuthenticationService } from '@services/laravel/authentication.service';
import { CrudService } from '@services/laravel/crud.service';
import { SnackBarService } from '@services/snackbar.service';
import { LoadingModalComponent } from '@shared/components/loading-modal/loading-modal.component';
import { ToastrService } from 'ngx-toastr';

export interface User {
  id?: string;
  description?: string;
}

export interface Application {
  id?: string;
  description?: string;
}

@Component({
  selector: 'app-import-verification-list',
  templateUrl: './import-verification-list.component.html',
  styleUrls: ['./import-verification-list.component.css'],
})
export class ImportVerificationListComponent implements OnInit {
  @Output()
  importVerificationChange: EventEmitter<string> = new EventEmitter<string>();
  @ViewChildren('verificationListCheckbox')
  private verificationListCheckbox: QueryList<any>;

  displayedColumns: string[] = ['select', 'type', 'particularity', 'preview'];

  optionsUser: User[] = [];
  optionsApplication: Application[] = [];

  isLoading = false;
  validForm = false;
  public userArray: any;
  public eventArray: any;
  public applicationArray: any;
  public verificationListArray: any;
  public courseArray: any;
  event_id: any;
  msg;

  importVerificationListForm: UntypedFormGroup;
  addUsersForm: UntypedFormGroup;
  myControlUser = new UntypedFormControl();
  myControlApplication = new UntypedFormControl();
  filteredOptionsUser: Observable<User[]>;
  filteredOptionsApplication: Observable<Application[]>;
  public associatedUsers: any = [];

  constructor(
    private authentication: AuthenticationService,
    public dialogRef: MatDialogRef<ImportVerificationListComponent>,
    private router: Router,
    private _crud: CrudService,
    public mdsnackbar: MatSnackBar,
    private dialog: MatDialog,
    public _snackbar: MatSnackBar,
    public snackBarService: SnackBarService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.formInit();
    this.listApplications();
    this.listCourses();
  }

  listApplications = () => {
    this.isLoading = true;
    this._crud
      .post('events/general-search', {
        fields: {},
        dates: {},
        orderBy: { description: 'asc' },
        limit: null,
        noPaginate: true,
        page: null,
      })
      .then((res) => {
        this.applicationArray = res['data'];
        this.importVerificationListForm.get('source_event_id').enable();
        this.isLoading = false;
      });
  };

  listCourses = () => {
    this._crud
      .post('course-event/general-search', {
        fields: {},
        dates: {},
        // "orderBy": { 'description': 'asc' },
        where: {
          event_id: this.importVerificationListForm.value.source_event_id,
        },
        limit: null,
        noPaginate: true,
        page: null,
      })
      .then((res) => {
        this.courseArray = res['data'];
        this.importVerificationListForm.get('course_id').enable();
      });
  };

  listVerificationLists() {
    this.isLoading = true;
    this._crud
      .post('list-verification/general-search-without-context', {
        fields: {},
        dates: {},
        where: {
          event_id: this.importVerificationListForm.value.source_event_id,
          'matrix:course_id': this.importVerificationListForm.value.course_id,
          is_active: 1,
        },
        noContext: true,
        limit: null,
        noPaginate: true,
        page: null,
      })
      .then((res) => {
        this.verificationListArray = res['data'];
        this.isLoading = false;
      });
  }

  formInit() {
    this.importVerificationListForm = new UntypedFormGroup({
      source_event_id: new UntypedFormControl({ value: null, disabled: true }, [
        Validators.required,
      ]),
      course_id: new UntypedFormControl({ value: null, disabled: true }, [
        Validators.required,
      ]),
      destination_event_id: new UntypedFormControl(this.event_id),
      lvs_ids: new UntypedFormControl(null),
    });
  }

  close = () => {
    this.dialogRef.close();
  };

  previewVerificationList(lv) {
    this.loadingMessage();

    this._crud
      .file('list-verification/preview', {
        id: lv.id,
      })
      .then(
        (res) => {
          saveAs(res, 'verification_list_preview.pdf');
          this.dialog.closeAll();
        },
        (rej) => {
          this.toastr.error('Falha do tentar gerar Preview', 'Erro!');
          this.dialog.closeAll();
        }
      );
  }

  loadingMessage() {
    const array = [];
    const string = '';

    const dialogRef = this.dialog.open(LoadingModalComponent, {
      width: '520px',
      height: 'auto',
      disableClose: true,
    });
    dialogRef.componentInstance.title = 'Aguarde...';
    dialogRef.componentInstance.mensagem = 'Carregando dados...';
    dialogRef.componentInstance.showBtn = false;
  }

  importVerificationList = () => {
    const verificationListCheckbox = this.verificationListCheckbox.toArray();
    const lvs_ids = [];

    for (let i = 0; i < verificationListCheckbox.length; i++) {
      if (verificationListCheckbox[i].checked) {
        lvs_ids.push(verificationListCheckbox[i].value.toString());
      }
    }

    this.importVerificationListForm.get('lvs_ids').setValue(lvs_ids);

    this._crud
      .post(
        'evaluation-items/list-verification',
        this.importVerificationListForm.value
      )
      .then((res) => {
        this.toastr.success('Provas importadas com Sucesso!', 'Sucesso!');
        this.dialogRef.close();
      });
  };
}
