import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

/*Services*/
import { CrudService } from '@services/laravel/crud.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-ntm-modal-confirm',
  templateUrl: './modal-confirm.component.html',
  styleUrls: ['./modal-confirm.component.css'],
})
export class ModalConfirmComponent {
  @Output()
  modalConfirmChange: EventEmitter<string> = new EventEmitter<string>();

  dataToDelete: any;
  dialogTitle = 'Desativar Registro';
  dialogMessage: string;
  resend: boolean;
  confirmText = 'Sim';
  cancelText = 'Não';
  constructor(
    public dialogRef: MatDialogRef<ModalConfirmComponent>,
    private crud: CrudService,
    private router: Router,
    private matsnackbar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toastr: ToastrService
  ) {}

  confirm() {
    this.dialogRef.close(true);
  }

  close() {
    this.dialogRef.close();
  }
}
