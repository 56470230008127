import { Component, OnInit, Output } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

/*Services*/
import { CrudService } from '@services/laravel/crud.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-exam-location',
  templateUrl: './exam-location.component.html',
  styleUrls: ['./exam-location.component.css'],
})
export class ExamLocationComponent implements OnInit {
  @Output()
  public school_id: any;
  public schoolLocationForm: UntypedFormGroup;
  public isLoading = true;
  public arrPlace = [];

  constructor(
    public dialogRef: MatDialogRef<ExamLocationComponent>,
    private _crud: CrudService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.listPlace();

    this.schoolLocationForm = new UntypedFormGroup({
      description: new UntypedFormControl('', [Validators.required]),
      school_id: new UntypedFormControl(this.school_id),
    });
  }

  listPlace() {
    this._crud
      .get(
        'place?where=school_id,' +
          this.school_id +
          '$noPaginate=true&noRelationship=true&order=description,asc'
      )
      .then((res: any) => {
        this.arrPlace = res.data || res;
        this.isLoading = false;
      })
      .catch((err) => {
        this.isLoading = false;
      });
  }

  close = () => {
    this.dialogRef.close();
  };

  addExamLocation = () => {
    this.isLoading = true;
    this._crud
      .post('place', this.schoolLocationForm.value)
      .then((res: any) => {
        this.toastr.success(res.message, 'Sucesso!');
        this.listPlace();
        this.schoolLocationForm.controls['description'].reset();
      })
      .catch((err) => {
        this.isLoading = false;
      });
  };

  removePlace(id) {
    this.isLoading = true;
    this._crud
      .delete('place/' + id)
      .then((res: any) => {
        this.toastr.success(res.message, 'Sucesso!');
        this.listPlace();
      })
      .catch((err) => {
        this.isLoading = false;
      });
  }
}
