import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

/*Services*/
import { CrudService } from '@services/laravel/crud.service';

@Component({
  selector: 'app-ntm-loading-modal',
  templateUrl: './loading-modal.component.html',
  styleUrls: ['./loading-modal.component.css'],
})
export class LoadingModalComponent {
  @Output()
  loadingChange: EventEmitter<string> = new EventEmitter<string>();

  title: any;
  mensagem: any;
  status: any;
  color: any;
  showBtn: boolean = true;
  setTimer: boolean = false;

  alert_type_1: any = false;
  alert_type_2: any = false;

  dataToDelete: any;
  dialogMessage: string;
  constructor(
    public dialogRef: MatDialogRef<LoadingModalComponent>,
    private crud: CrudService,
    private router: Router,
    private matsnackbar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  onDialogClose = () => {
    this.dialogRef.close(false);
  };
}
