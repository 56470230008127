<app-modal-header [hasClose]='true' [title]="'ADICIONAR USUÁRIOS'" (handleClose)="close()"></app-modal-header>

<form [formGroup]="importUsersForm" appFocusFirstInvalidField (ngSubmit)="myControlUser.valid && myControlApplication.valid && importUsersFromEvent()">
  <mat-dialog-content class="content-dialog">
    <div id="text" class="primary-text">
      Busque os usuários que deseja adicionar nessa aplicação e clique em SELECIONAR.
    </div>

    <div class="">
      <mat-form-field class="">
        <input type="text" [formControl]="myControlApplication" placeholder="Aplicação *" aria-label="Aplicação" matInput [matAutocomplete]="autoApplication">
        <mat-autocomplete #autoApplication="matAutocomplete" [displayWith]="displayFnApplication" matSelect placeholder="Escolha a Aplicação" (optionSelected)="addApplication()">
          <mat-option *ngFor="let application of filteredOptionsApplication | async" [value]="application">
            {{application.description}}
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="myControlApplication.errors && myControlApplication.touched">
          {{myControlApplication.errors['message']}}
        </mat-error>
      </mat-form-field>

      <mat-form-field class="">
        <input type="text" [formControl]="myControlUser" placeholder="Usuário *" aria-label="Usuário" matInput [matAutocomplete]="auto" (keyup)="listUsers()">
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFnUser" matSelect placeholder="Escolha o Usuário" (optionSelected)="addUser()">
          <mat-option *ngFor="let user of filteredOptionsUser | async" [value]="user">
            {{user.description}}
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="myControlUser.errors && myControlUser.touched">
          {{myControlUser.errors['message']}}
        </mat-error>
      </mat-form-field>
    </div>

  </mat-dialog-content>

  <div class="modal-footer">
    <!-- <button type="button" mat-raised-button (click)="close()">
      Cancelar
    </button> -->

    <button id="modalApplyBtn" type="submit" mat-raised-button color="accent" class="far-left">
      Adicionar Usuários
    </button>
  </div>
</form>
